import iub from '@utils/ImageUrlBuilder';
import React from 'react';

import styles from './index.module.scss';

type Props = {
  asset;
  alt: string;
  caption: string;
};

const SingleImg = (image: Props): JSX.Element => {
  return (
    <div className={styles.root}>
      <div
        style={{ backgroundImage: `url(${iub(image?.asset).url()})` }}
        title={image?.alt}
        className={styles.singleImg}
      />
      <p className={styles.caption}>{image.caption}</p>
    </div>
  );
};

export default SingleImg;
