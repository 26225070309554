import SimpleBlockContent from '@bits/SimpleBlockContent';
import { basicInVewOptions } from '@utils/animations/fadeSettings';
import React, { FunctionComponent, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import styles from './index.module.scss';

type Props = {
  label: string;
  heading: string;
  text;
  previewText?: string;
  alignTextCenter: boolean;
  alignTextRight: boolean;
  buttonText?: string;
  buttonTextClosed?: string;
};

const TextSection: FunctionComponent<Props> = ({
  label,
  heading,
  text,
  previewText,
  alignTextCenter,
  alignTextRight,
  buttonText,
  buttonTextClosed,
}) => {
  const [open, setOpen] = useState(false);
  const { ref, inView } = useInView(basicInVewOptions);

  return (
    <div
      className={`${styles.root} ${alignTextCenter && styles.center ? styles.center : ''} ${inView ? styles['in-view'] : ''
        }  ${alignTextRight && styles.right ? styles.right : ''} ${!alignTextRight && !alignTextCenter && styles.left ? styles.left : ''
        }`}>
      {label && <span style={{ transitionDelay: `${75}ms` }}>{label}</span>}
      {heading && <h4 style={{ transitionDelay: `${75}ms` }}>{heading}</h4>}
      <div className={styles.grid} style={{ transitionDelay: `${150}ms` }}>
        <span ref={ref}></span>

        <div className={`${styles.container} ${!open ? styles.closed : ''}`}>
          {
            open ? <SimpleBlockContent data={text} /> : <SimpleBlockContent data={previewText || text} />
          }

        </div>
        <span style={{ transitionDelay: `${225}ms` }}>
          <button className={styles.button} onClick={() => setOpen(!open)}>{!open ? buttonText || 'Les mer' : buttonTextClosed || 'Lukk'}</button>
        </span>
      </div>
    </div >
  );
};

export default TextSection;
