import React, { FunctionComponent } from 'react';

import AccordionList from './AccordionList';
import styles from './index.module.scss';
type Props = {
  accordion;
  title: string;
  formId: string;
};

const AccordionWithTitle: FunctionComponent<Props> = ({ accordion, title, formId }): JSX.Element => {

  return (
    <div id={formId || '#'} className={styles.root}>
      <h3>{title}</h3>

      <AccordionList {...accordion} />
    </div>
  );
};

export default AccordionWithTitle;
