import React, { FunctionComponent } from 'react';

import styles from './index.module.scss';

type Props = {
  type?: string;
  desc?: string;
  defaultValue: string | number;
  style?;
  onChange: (e) => void;
};

const InputWithDesc: FunctionComponent<Props> = ({ type, desc, defaultValue, style, onChange }) => {
  return (
    <span className={styles.root}>
      <span className={styles['input-container']}>
        <input
          type={type || 'text'}
          name="first_name"
          placeholder={''}
          defaultValue={defaultValue}
          onChange={onChange}
          style={style}
        />
      </span>
      <span>{desc}</span>
    </span>
  );
};

export default InputWithDesc;
