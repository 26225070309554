import React, { FunctionComponent } from 'react';

import styles from './index.module.scss';

type Props = {
  type: string;
};

const NoPlug: FunctionComponent<Props> = ({ type }) => {
  return (
    <div className={styles.root}>
      <span>{type}</span>
    </div>
  );
};

export default NoPlug;
