import React from 'react';

const arrowRight = (color): JSX.Element => {
  return (
    <svg
      style={{ zIndex: 10 }}
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40">
      <circle cx="20" cy="20" r="19.5" stroke={color}></circle>
      <g fill={color}>
        <path d="M24.072 22.286l-.549-.566L25.188 20l-1.665-1.719.549-.566L26.285 20l-2.213 2.285z"></path>
        <path d="M25.737 19.602H13.714v.8h12.023v-.8z"></path>
      </g>
    </svg>
  );
};

function arrowUp(color): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40">
      <circle cx="20" cy="20" r="19.5" stroke={color} transform="rotate(-90 20 20)"></circle>
      <path
        fill={color}
        d="M22.286 15.928l-.567.549L20 14.812l-1.72 1.665-.566-.549L20 13.715l2.286 2.213z"></path>
      <path fill={color} d="M19.6 14.262v12.023h.8V14.262h-.8z"></path>
    </svg>
  );
}

function ArrowWithCircle({ color = 'white', direction = 'right' }): JSX.Element {
  return <>{direction === 'right' ? arrowRight(color) : arrowUp(color)}</>;
}

export default React.memo(ArrowWithCircle);
