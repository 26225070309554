import React from 'react';

const Phone = (): JSX.Element => {
  return (
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="27" cy="27" r="26.5" stroke="black" />
      <path
        d="M29 20.5C32.584 20.5 35.5 23.416 35.5 27C35.5 27.276 35.724 27.5 36 27.5C36.276 27.5 36.5 27.276 36.5 27C36.5 22.864 33.136 19.5 29 19.5C28.724 19.5 28.5 19.724 28.5 20C28.5 20.276 28.724 20.5 29 20.5ZM29 24.5C30.379 24.5 31.5 25.622 31.5 27C31.5 27.276 31.724 27.5 32 27.5C32.276 27.5 32.5 27.276 32.5 27C32.5 25.07 30.93 23.5 29 23.5C28.724 23.5 28.5 23.724 28.5 24C28.5 24.276 28.724 24.5 29 24.5ZM34.999 32.009C33.827 32.009 32.679 31.826 31.591 31.467C31.061 31.284 30.457 31.426 30.108 31.781L27.951 33.41C25.477 32.09 23.893 30.507 22.589 28.049L24.174 25.943C24.573 25.543 24.716 24.96 24.545 24.413C24.184 23.319 24 22.17 24 21C24 20.173 23.327 19.5 22.5 19.5H19C18.173 19.5 17.5 20.173 17.5 21C17.5 30.649 25.351 38.5 35 38.5C35.827 38.5 36.5 37.827 36.499 37V33.509C36.499 32.682 35.826 32.009 34.999 32.009ZM35.5 37C35.5 37.276 35.275 37.5 35 37.5C25.902 37.5 18.5 30.098 18.5 21C18.5 20.724 18.725 20.5 19 20.5H22.5C22.775 20.5 23 20.724 23 21C23 22.277 23.2 23.531 23.592 24.72C23.65 24.907 23.603 25.1 23.42 25.29L21.6 27.7C21.485 27.853 21.467 28.058 21.554 28.228C23.037 31.141 24.838 32.943 27.772 34.446C27.941 34.534 28.148 34.516 28.301 34.4L30.763 32.534C30.896 32.403 31.093 32.355 31.272 32.415C32.468 32.81 33.722 33.01 35 33.01C35.275 33.01 35.5 33.234 35.5 33.51V37Z"
        fill="black"
      />
    </svg>
  );
};

export default React.memo(Phone);
