import CTALink from '@bits/CtaLink';
import SimpleBlockContent from '@bits/SimpleBlockContent';
import { basicInVewOptions } from '@utils/animations/fadeSettings';
import React, { FunctionComponent } from 'react';
import { useInView } from 'react-intersection-observer';

import styles from './index.module.scss';

type Props = {
  label: string;
  heading: string;
  text;
  cta;
  alignTextCenter: boolean;
  alignTextRight: boolean;
  gotoForm?: string;
};

const TextSection: FunctionComponent<Props> = ({
  label,
  heading,
  text,
  cta,
  alignTextCenter,
  alignTextRight,
  gotoForm
}) => {
  const { ref, inView } = useInView(basicInVewOptions);

  return (
    <div
      className={`${styles.root} ${alignTextCenter && styles.center ? styles.center : ''} ${inView ? styles['in-view'] : ''
        }  ${alignTextRight && styles.right ? styles.right : ''} ${!alignTextRight && !alignTextCenter && styles.left ? styles.left : ''
        }`}>
      {label && <span style={{ transitionDelay: `${75}ms` }}>{label}</span>}
      {heading && <h4 style={{ transitionDelay: `${75}ms` }}>{heading}</h4>}
      <div className={styles.grid} style={{ transitionDelay: `${150}ms` }}>
        <span ref={ref}></span>
        <div className={styles.container}>
          <SimpleBlockContent data={text} />
        </div>

        {cta && (
          <span style={{ transitionDelay: `${225}ms` }}>

            {
              gotoForm
                ? <CTALink title={cta.title} url={`/#${gotoForm}`} backgroundColor={'#000000'} color={'#FFFFFF'} />
                : <CTALink title={cta.title} cta={cta} backgroundColor={'#000000'} color={'#FFFFFF'} />
            }


          </span>
        )}
      </div>
    </div>
  );
};

export default TextSection;
