import SquareButton from '@components/bits/SquareButton';
import { basicInVewOptions } from '@utils/animations/fadeSettings';
import React, { FunctionComponent } from 'react';
import { useInView } from 'react-intersection-observer';

import styles from './index.module.scss';
type Props = {
  cta;
  quote;
};

const QuoteWithImage: FunctionComponent<Props> = ({ cta, quote }) => {
  const { ref, inView } = useInView(basicInVewOptions);

  return (
    <div ref={ref} className={`${styles.root} ${inView ? styles['in-view'] : ''}`}>
      <div className={styles['_content']}>
        <p>{quote}</p>
        {cta && <SquareButton cta={cta} />}
      </div>
    </div>
  );
};

export default QuoteWithImage;
