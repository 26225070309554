import ArrowWithCircle from '@bits/icons/ArrowWithCircle';
import Link from 'next/link';
import React, { FunctionComponent } from 'react';

import styles from './index.module.scss';
type Props = { description: string; btnText: string };

const ContactCtaPlug: FunctionComponent<Props> = ({ description, btnText }) => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <p>{description || 'Sett description i sanity'}</p>
        <Link href={'/kontakt'}>
          <a className={styles.link}>
            {btnText || 'Contact us'}
            <ArrowWithCircle color={'white'} />
          </a>
        </Link>
      </div>
    </div>
  );
};

export default ContactCtaPlug;
