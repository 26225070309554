import React, { FunctionComponent, useEffect } from 'react';
import { useState } from 'react';

import styles from './index.module.scss';
import ListElement from './ListElement';

type Props = {
  lists;
  title: string;
  showAll: boolean;
};

const getNoSelectedList = (lists, showAll) => {
  let list = [];

  if (showAll) {
    list = lists.map((list) => {
      return list?.elements?.map((item, itemKey) => {
        return (
          <ListElement key={itemKey} {...item} />
        )
      })
    })
  } else if(lists.length) {
    list = lists[0]?.elements?.map((item, itemKey) => {
      return (
        <ListElement key={itemKey} {...item} />
      )
    })
  }

  return list;
}

const PeopleGridLayout: FunctionComponent<Props> = ({ lists, title, showAll }) => {
  const [activeList, setActiveList] = useState(null);

  useEffect(() => {
    if (!showAll && lists.length) {
      setActiveList(lists[0].title)
    }
  }, []);

  return (
    <div className={styles.root}>
      <h2>{title}</h2>
      <div className={styles.menu}>
        {showAll && (<button
          className={`${!activeList ? styles['active'] : ''}`}
          onClick={() => setActiveList(null)}
          data-text={'Alle'}
        >{'Alle'}</button>
        )}
        {

          lists.map((val, key) => <button
            className={`${activeList === val.title ? styles['active'] : ''}`}
            onClick={() => setActiveList(val.title)}
            data-text={val.title}
            key={key}>{val.title}</button>)
        }
      </div>
      <div className={styles['grid-layout']}>
        {
          !activeList
            ? getNoSelectedList(lists, showAll)
            : (
              lists.filter(list => list.title === activeList).map((filteredList) => {
                return filteredList?.elements?.map((item, itemKey) => {
                  return (
                    <ListElement key={itemKey} {...item} />
                  )
                })

              })
            )
        }
      </div>
    </div>
  );
};

export default PeopleGridLayout;
