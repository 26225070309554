import ArrowRight from '@bits/icons/ArrowRight';
import Phone from '@bits/icons/Phone';
import SimpleBlockContent from '@bits/SimpleBlockContent';
import React, { FunctionComponent } from 'react';

import styles from './index.module.scss';

type Props = {
  headerWithBody;
  links;
  contactName: string;
  contactPhone: string;
};

const TextWithLinks: FunctionComponent<Props> = ({
  headerWithBody,
  links,
  contactName,
  contactPhone,
}) => {
  return (
    <div className={styles.root}>
      <h4>{headerWithBody.header}</h4>

      <div className={styles.grid}>
        <div className={styles.container}>
          <SimpleBlockContent data={headerWithBody.body} />
        </div>

        <div className={styles.sideContainer}>
          {links?.map((cta, key) => (
            <div key={key} className={styles.link}>
              {/* <CtaLink cta={cta} text={cta.title} /> */}
              {/* Cretae a new CtaLink for this */}
              <ArrowRight />
            </div>
          ))}
          <div className={styles.contactBox}>
            <Phone />
            <div className={styles.infoWrapper}>
              <p>
                <b>{contactName}</b>
              </p>
              <a href={`tel: ${contactPhone}`}>{contactPhone}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextWithLinks;
