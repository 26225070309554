import CTALink from '@bits/CtaLink';
import SimpleBlockContent from '@bits/SimpleBlockContent';
import { basicInVewOptions } from '@utils/animations/fadeSettings';
import iub from '@utils/ImageUrlBuilder';
import React, { FunctionComponent } from 'react';
import { useInView } from 'react-intersection-observer';

import styles from './index.module.scss';

type Props = {
  label: string;
  text;
  cta;
  image;
};

const TextImageCard: FunctionComponent<Props> = ({ label, text, cta, image }) => {
  const { ref, inView } = useInView(basicInVewOptions);
  return (
    <div ref={ref} className={`TextImageCard ${styles.root} ${inView ? styles['in-view'] : ''}`}>
      <div className={`left ${styles.content}`}>
        <h4 style={{ transitionDelay: `${75}ms` }}>{label}</h4>
        <div style={{ transitionDelay: `${150}ms` }} className={styles.container}>
          <SimpleBlockContent data={text} />
        </div>
        {cta && <CTALink title={cta.title} cta={cta} backgroundColor={'#435053'} color={'white'} />}
      </div>
      <div
        style={{ backgroundImage: `url(${iub(image?.asset).url()})`, transitionDelay: `${25}ms` }}
        title={image?.alt}
        className={`right ${styles.singleImg}`}
      />
    </div>
  );
};

export default TextImageCard;
