import useWindowDimensions from '@utils/hooks/useWindowDimension';
import React, { FunctionComponent } from 'react';
import SimpleImageSlider from "react-simple-image-slider";
interface Size {
  width: number | undefined;
  height: number | undefined;
}
import styles from './index.module.scss';
type Props = { referenceList };

const stylesArray = ['one-image', 'two-images', 'three-images'];

const ImageGallery: FunctionComponent<Props> = ({ referenceList }) => {
  const size: Size = useWindowDimensions();
  const galleryStyle = stylesArray[referenceList.length - 1];

  const images = referenceList?.map((val) => {
    return { url: val?.asset?.url }
  });


  return (
    <div className={`${styles.root} ${styles[galleryStyle]}`}>
      {size.width <= 768
        ? (
          <SimpleImageSlider
            width={size.width*1}
            height={size.height*0.6}
            slideDuration={1.1}
            images={images}
            showBullets={false}
            autoPlay={true}
            autoPlayDelay={3.5}
            showNavs={false}
          />
        )
        : (
          images.map((val, key) => {
            return (
              <div key={key}>
                <div
                  style={{
                    backgroundImage: `url(${val?.url}`,
                  }}></div>
              </div>
            );
          })
        )
      }
    </div>
  );
};

export default ImageGallery;
