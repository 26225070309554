import iub from '@utils/ImageUrlBuilder';
import React, { FunctionComponent } from 'react';

import styles from './index.module.scss';
type Props = { title; mediaLinks };

const MediaCard: FunctionComponent<Props> = ({ title, mediaLinks }) => {
  return (
    <div className={styles.root}>
      <span>{title}</span>
      <div className={styles.linksWrapper}>
        {mediaLinks.map((link, key) => (
          <a href={link?.link?.href} className={`link ${styles.link}`} key={key}>
            <div
              style={{ backgroundImage: `url(${iub(link?.icon?.asset).url()})` }}
              title={link?.alt}
              className={styles.img}
            />
            {link?.label}
          </a>
        ))}
      </div>
    </div>
  );
};

export default MediaCard;
