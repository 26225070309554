/* eslint-disable react/no-multi-comp */

import keys from 'lodash/keys';
// import dynamic from 'next/dynamic';
// import PropTypes from 'prop-types';
import React, { Component } from 'react';

import accordionWithTitle from './AccordionWithTitle';
import banner from './Banner'; /* webpackPreload: true */
import brandList from './BrandList'; /* webpackPreload: true */
import brandLogo from './BrandLogo';
/* */
import contactCtaPlug from './ContactCtaPlug'; /* webpackPreload: true */
import contactTextCloud from './ContactTextCloud';
import expandableTextSection from './ExpandableTextSection'; /* webpackPreload: true */
/* FORMS */
import contactPlug from './forms/ContactPlug'; /* webpackPreload: true */
import headerWithBody from './HeaderWithBody'; /* webpackPreload: true */
import hero from './Hero';
import infoBanner from './InfoBanner';
import linkBanner from './LinkBanner';
import mediaCard from './MediaCard';
// Load all as normal imports
import NoPlug from './NoPlug'; /* webpackPreload: true */
import listInfoSplitView from './PeopleGridLayout';
import Plug from './Plug'; /* webpackPreload: true */
import quoteWithImage from './QuoteWithImage';
import singleImg from './SingleImg'; /* webpackPreload: true */
import singleImgMini from './SingleImgMini'; /* webpackPreload: true */
import textImageCard from './TextImageCard'; /* webpackPreload: true */
import textSection from './TextSection'; /* webpackPreload: true */
import textWithImageGallery from './TextWithImageGallery';
import textWithLinks from './TextWithLinks';

// Load all as dynamic imports
const enabledPlugs = {
  banner,
  headerWithBody,
  contactPlug,
  contactCtaPlug,
  textWithLinks,
  linkBanner,
  hero,
  brandList,
  textSection,
  expandableTextSection,
  textImageCard,
  singleImg,
  singleImgMini,
  contactTextCloud,
  listInfoSplitView,
  infoBanner,
  mediaCard,
  brandLogo,
  textWithImageGallery,
  accordionWithTitle,
  quoteWithImage,
};

type Plug = {
  _key: string;
  _type: string;
  plugs: PlugProps[];
};

type PlugProps = {
  plugs: Plug[];
};

class PlugsResolver extends Component<PlugProps> {
  resolvePlug = (plug: Plug): JSX.Element => {
    const Component = enabledPlugs[plug._type];

    if (Component) {
      return (
        <Plug key={plug._key}>
          <Component {...plug} />
        </Plug>
      );
    }

    return <NoPlug type={plug._type} key={plug._key} />;
  };

  render(): JSX.Element | unknown {
    const { plugs }: PlugProps = this.props;

    if (!plugs) {
      return <div>No plugs</div>;
    }

    const elements = plugs.map((plug: Plug) => {
      if (keys(plug).length === 0) {
        return <div key={plug._key}> Wait for conditional in GROQ </div>;
      }

      return this.resolvePlug(plug);
    });

    return elements;
  }
}

export default PlugsResolver;
