import iub from '@utils/ImageUrlBuilder';
import React, { FunctionComponent } from 'react';

import css from './index.module.scss';

type Props = {
  image;
  alt;
  maxHeight: string;
};

const FullscreenImage: FunctionComponent<Props> = ({ image, alt = '', maxHeight = 'auto' }) => {
  const style = {
    backgroundImage: `url(${iub(image).width(1600).url()})`,
    maxHeight,
  };

  return <div title={alt} className={css['root']} style={style} />;
};

export default FullscreenImage;
