import iub from '@utils/ImageUrlBuilder';
import { useRouter } from 'next/router';
import React, { FunctionComponent, memo, useEffect, useRef, useState } from 'react';

import styles from './index.module.scss';

const STOPPED = 'stopped';
const PLAYING = 'playing';
const ENDING = 'ending';

type AnimationProps = {
  currX: number;
  currY: number;
  currS: number;
  gotoX: number;
  gotoY: number;
  gotoS: number;
  speedX: number;
  speedY: number;
  speedS: number;
};

type Props = {
  width?: number;
  height?: number;
  interactive?: boolean;
  className?: string;
  url?: () => string;
  image;
};

const InterActiveImage: FunctionComponent<Props> = ({ width, height, image, className, url, interactive = true }) => {
  const imageRef = useRef<HTMLImageElement>();
  const animationRef = useRef<number | null>(null);
  const stateRef = useRef<AnimationProps>({
    currX: -0.3,
    currY: -0.5,
    currS: 1,
    gotoX: 0,
    gotoY: 0,
    gotoS: 1,
    speedX: 1,
    speedY: 1,
    speedS: 3,
  });
  const [animating, setAnimating] = useState(STOPPED);

  const router = useRouter();

  const imageStyling = useRef({ s: {} });

  useEffect(() => {
    if (!image) return;

    const { hotspot } = image;

    if (hotspot) {
      imageStyling.current.s = {
        objectFit: 'cover',
        objectPosition: `${hotspot.x * 100}% ${hotspot.y * 100}%`,
      }
    }


  }, [image]);

  const fps = 60;
  const fpsInterval = 1000 / fps;
  let then = 0;

  let img = iub(image);
  if (width) {
    img = img.width(width);
  }
  if (height) {
    img = img.height(height);
  }

  useEffect(() => {
    return () => cancelAnimationFrame(animationRef.current);
  }, []);

  useEffect(() => {
    if (animating !== STOPPED && interactive) {
      animationRef.current = requestAnimationFrame(anim);
    } else {
      cancelAnimationFrame(animationRef.current);
    }
    return () => {
      cancelAnimationFrame(animationRef.current);
    };
  }, [animating]);

  const setEnterPos = (): void => {
    if (!interactive) return

    stateRef.current.gotoS = 1.1;
    stateRef.current.speedS = 7;
    then = Date.now();
    setAnimating(PLAYING);
  };
  const resetEnterPos = (): void => {
    stateRef.current.gotoX = 0;
    stateRef.current.gotoY = 0;
    stateRef.current.gotoS = 1;
    stateRef.current.speedX = 5;
    stateRef.current.speedY = 5;
    stateRef.current.speedS = 3;
    setAnimating(ENDING);
  };

  const updateImagePos = (evt): void => {
    if (!interactive) return;

    const mPosX = evt.nativeEvent.layerX;
    const mPosY = evt.nativeEvent.layerY;
    const w = imageRef.current.clientWidth;
    const h = imageRef.current.clientHeight;

    const px = (-0.3 + mPosX / w) * -1;
    const py = (-0.5 + mPosY / h) * -1;

    stateRef.current.gotoX = px * 4;
    stateRef.current.gotoY = py * 4.5;

    stateRef.current.speedX = Math.max(2, 3 - evt.movementX);
    stateRef.current.speedY = Math.max(2, 3 - evt.movementY);
  };

  const anim = (): void => {
    const now = Date.now();
    const elapsed = now - then;

    if (animating !== STOPPED) {
      animationRef.current = requestAnimationFrame(anim);
    }

    const { currS, gotoS, gotoX, currX, gotoY, currY, speedX, speedY, speedS } = stateRef.current;

    if (elapsed > fpsInterval) {
      then = now;

      if (animating === PLAYING) {
        stateRef.current.currX += (gotoX - currX) / speedX;
        stateRef.current.currY += (gotoY - currY) / speedY;
        stateRef.current.currS += (gotoS - currS) / speedS;
      }

      if (animating === ENDING) {
        stateRef.current.currX += (gotoX - currX) / speedX;
        stateRef.current.currY += (gotoY - currY) / speedY;
        stateRef.current.currS += (gotoS - currS) / speedS;

        const checkX = Math.abs(gotoX - currX) < 0.05;
        const checkY = Math.abs(gotoY - currY) < 0.05;
        const checkS = Math.abs(gotoS - currS) < 0.05;

        if (checkX && checkY && checkS) {
          imageRef.current.style.transform = `scale(${1}) translate3d(${0}%, ${0}%, 0)`;
          setAnimating(STOPPED);
        }
      }

      imageRef.current.style.transform = `scale(${currS}) translate3d(${currX}%, ${currY}%, 0)`;
    }
  };

  return (
    <span
      role='presentation'
      onKeyDown={() => null}
      className={`${styles['root']} ${className}`}
      onMouseEnter={setEnterPos}
      onMouseLeave={resetEnterPos}
      onMouseMove={updateImagePos}
      style={{ cursor: url ? 'pointer' : 'default' }}
      onClick={() => {
        if (url) {
          setAnimating(STOPPED);
          const navUrl = url();
          router.push(`${navUrl}`, undefined, { shallow: true });
        }
      }}>
      <img alt="" ref={imageRef} src={img.dpr(2).url()} style={imageStyling.current.s} />
    </span>
  );
};

const areEqual = (prevProps, nextProps):boolean => {
  // width, height, image, className, url, interactive
  if(prevProps.image !== nextProps.image) return false
  if(prevProps.url !== nextProps.url) return false

  return true;
}

export default memo(InterActiveImage, areEqual);
