import CTALink from '@components/bits/CtaLink';
import SimpleBlockContent from '@components/bits/SimpleBlockContent';
import React, { FunctionComponent } from 'react';

import styles from './index.module.scss';
type Props = { header: string; body; alignTextCenter: boolean; textAlignCenter: boolean, file; };

const HeaderWithBody: FunctionComponent<Props> = ({
  header,
  body,
  alignTextCenter,
  textAlignCenter,
  file,
}) => {

  return (
    <div
      className={`${styles.root} ${alignTextCenter && styles.center} ${
        textAlignCenter && styles.centerText
      }`}>
      <h4>{header}</h4>
      <SimpleBlockContent data={body} />
      {
        file?.download?.asset?.url && <CTALink url={file?.download?.asset?.url} title={file.buttonText} download={file?.download?.asset?.originalFilename} />
      }
    </div>
  );
};

export default HeaderWithBody;
