import React from 'react';

const TallArrowDown = (): JSX.Element => {
  return (
    <svg width="21" height="29" viewBox="0 0 21 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="11.7988" width="26" height="2" transform="rotate(90 11.7988 0)" fill="#435053" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.998943 18.8993L9.48422 27.3846L10.8984 28.7988L12.3127 27.3846L20.7979 18.8993L19.3837 17.4851L10.8984 25.9704L2.41316 17.4851L0.998943 18.8993Z"
        fill="#435053"
      />
    </svg>
  );
};

export default React.memo(TallArrowDown);
