const internalLinkProjection = /* groq */ `
  markDefs[]{
    ...,
    _type == "internalLink" => {
      "slug": @.reference->slug
    }
  }
`;

// Used in Node scripts
export { internalLinkProjection };
