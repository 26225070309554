import SimpleBlockContent from '@bits/SimpleBlockContent';
import iub from '@utils/ImageUrlBuilder';
import React, { FunctionComponent } from 'react';

import styles from './index.module.scss';
type Props = { title: string; text; instaTag; alignTextCenter: boolean };

const InfoBanner: FunctionComponent<Props> = ({ title, text, instaTag, alignTextCenter }) => {
  return (
    <div className={`${styles.root} ${alignTextCenter && styles.center}`}>
      <div className={styles['wrapper-outer']}>
        <span className={styles['wrapper-inner']}>
          <span className={styles.title}>{title}</span>
          <div>
            {instaTag?.link && (
              <a href={instaTag.link.href} className={styles.instaWrapper}>
                {instaTag?.icon?.asset && (
                  <div
                    style={{ backgroundImage: `url(${iub(instaTag?.icon?.asset).url()})` }}
                    title={instaTag?.alt}
                    className={styles.img}
                  />
                )}
                {instaTag?.label}
              </a>
            )}
            {!instaTag?.link && (
              <span className={styles.instaWrapper}>
                {instaTag?.icon?.asset && (
                  <div
                    style={{ backgroundImage: `url(${iub(instaTag?.icon?.asset).url()})` }}
                    title={instaTag?.alt}
                    className={styles.img}
                  />
                )}
                {instaTag?.label}
              </span>
            )}
            <SimpleBlockContent data={text} />
          </div>
        </span>
      </div>
    </div>
  );
};

export default InfoBanner;
