import SimpleBlockContent from '@bits/SimpleBlockContent';
import React, { FunctionComponent } from 'react';

import styles from './index.module.scss';
type Props = { text };

const ContactTextCloud: FunctionComponent<Props> = ({ text }): JSX.Element => {
  return (
    <div className={styles.root}>
      <SimpleBlockContent data={text} />
    </div>
  );
};

export default ContactTextCloud;
