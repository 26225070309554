// import ThinArrowRight from '@components/bits/icons/ThinArrowRight';
import ThinArrowRight from '@components/bits/icons/ThinArrowRight';
import axios from 'axios';
import React, { FunctionComponent, useState } from 'react';

import InputWithDesc from '../bits/InputWithDesc';
import styles from './index.module.scss';

type Props = {
  basicForm;
  title: string;
};

const ContactPlug: FunctionComponent<Props> = ({ basicForm, title }) => {
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [sending, setSending] = useState<boolean>(false);

  // Errors
  const [fullNameError, setFullNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [mobileError, setMobileError] = useState('');

  if (success) {
    return (
      <div id={'kontakt'} className={styles.root}>
        <div className={styles.container}>
          <h2>{'Melding sendt!'}</h2>
          <p>Takk for at du kontaktet Gill-gruppen, vi vil ta kontakt med deg</p>
        </div>
      </div>
    );
  }

  const clearErrors = (): void => {
    setFullNameError('');
    setEmailError('');
    setMobileError('');
  };

  return (
    <div id={'kontakt'} className={styles.root}>
      <div className={styles.container}>
        <h2>{title || 'Sett title i sanity'}</h2>

        <form
          id="test-drive-form"
          onSubmit={async (ev) => {
            ev.preventDefault();

            clearErrors();

            const body = {
              full_name: fullName,
              email,
              phone,
              message,
            };
            try {
              setSending(true);
              const res = await axios.post('/api/mail/contact', body);
              setSending(false);
              if (res.status === 200) {
                setSuccess(true);
              }
            } catch (err) {
              setSending(false);
              console.log('err', err);

              if (err.response.data[0]?.context?.label === 'full_name') {
                console.log('full name error');
                setFullNameError('resp');
              } else if (err.response.data[0]?.context?.label === 'email') {
                console.log('emailerror');
                setEmailError('resp');
              } else if (err.response.data[0]?.context?.label === 'phone') {
                console.log('phone error');
                setMobileError('resp');
              }
            }
          }}
          className={styles.form}>
          <span className={styles['input-row']}>
            <InputWithDesc
              desc={basicForm?.placeFullName}
              defaultValue={fullName}
              onChange={(e) => setFullName(e.target.value)}
              style={{
                backgroundColor: fullNameError !== '' ? 'pink' : 'transparent',
              }}
            />
            <InputWithDesc
              type={'tel'}
              desc={basicForm?.placePhone || 'Telefonnummer'}
              defaultValue={phone}
              // onChange={() => null}
              onChange={(e) => setPhone(e.target.value)}
              style={{
                backgroundColor: mobileError !== '' ? 'pink' : 'transparent',
              }}
            />
            <InputWithDesc
              type={'email'}
              desc={basicForm?.placeEmail || 'Din-epost'}
              defaultValue={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                backgroundColor: emailError !== '' ? 'pink' : 'transparent',
              }}
            />
          </span>

          <span className={styles['single-input-row']}>
            <InputWithDesc
              type={'text'}
              desc={basicForm?.message || 'Beskjed til forhandler'}
              defaultValue={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </span>
          {sending ? (
            <span className={styles.noSubmitBtn}>
              <div id="loading"></div>
            </span>
          ) : (
            <button type="submit" className={styles.submitBtn}>
              {basicForm?.buttonName || 'Submit'}
              <ThinArrowRight />
            </button>
            // <PillButton text={basicForm?.buttonName || 'Submit'} />
          )}
        </form>
      </div>
    </div>
  );
};

export default ContactPlug;
