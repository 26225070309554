import { internalLinkProjection } from '@utils/internalLinkProjection';

import resolveLocalizationString from './resolveLocalizationString';
import resolveLocalizationText from './resolveLocalizationText';
import resolveLocalizationFile from './resolveLocalizedFile';

export const routeQuery = /* groq */ `
  *[_type == "page" && slug.current == $slug] {
    ...,
    ${resolveLocalizationString('title', '$locale')},
    ${resolveLocalizationText('description', '$locale')},
    slug,
    _type,
    image {
      ...,
      asset->
    },
    content[] {
      ...,
      ${resolveLocalizationFile('file', '$locale')} {
        ...,
        download {
          asset ->,
        }
      },
      
      plug {
        ...,
        backgroundImage {
          ...,
          backgroundImage {
            ...,
            asset->
          },
        },
      },
      _key,
      _type,
      alignTextCenter,
      textAlignCenter,
      ${resolveLocalizationString('title', '$locale')},
      ${resolveLocalizationString('header', '$locale')},
      ${resolveLocalizationString('heading', '$locale')},
      ${resolveLocalizationString('label', '$locale')},
      ${resolveLocalizationString('buttonText', '$locale')},
      ${resolveLocalizationString('buttonTextClosed', '$locale')},
      ${resolveLocalizationString('quote', '$locale')},
      ${resolveLocalizationText('body', '$locale')},
      ${resolveLocalizationText('text', '$locale')},
      ${resolveLocalizationText('previewText', '$locale')},
      asset-> {
        ...,
      },
      formId,
      cta {
        ...,
        ${resolveLocalizationString('title', '$locale')},
        route->
      },
      instaTag {
        ...,
        icon {
          asset->
        },
      },
      brandLogo {
        ...,
        logo {
          asset->
        },
      },
      imageGallery {
        referenceList[] {
          asset->
        }
      },
      _type == 'listInfoSplitView' => {
        ${resolveLocalizationString('title', '$locale')},
        lists[] {
          ${resolveLocalizationString('title', '$locale')},
          elements[] {
            ${resolveLocalizationString('title', '$locale')},
            ${resolveLocalizationString('subTitle', '$locale')},
            ${resolveLocalizationText('content', '$locale')},
            image {
              ...,
              asset->
            }
          }
        }
      },
      _type == 'textWithImageGallery' => {
        ...,
        ${resolveLocalizationText('text', '$locale')}[]{
          ...,
          ${internalLinkProjection}
        },

        imageGallery {
          referenceList[] {
            ...,
            asset->
          }
        }
      },
      _type == 'accordionWithTitle' => {
        accordion {
          elements[] {
            ${resolveLocalizationString('title', '$locale')},
            ${resolveLocalizationString('subTitle', '$locale')},
            ${resolveLocalizationText('content', '$locale')},
          }
        }
      },
      _type == 'hero' => {
        ...,
        video {
         asset ->
        },
        ${resolveLocalizationText('heroText', '$locale')},
      },
      _type == 'contactPlug' => {
        ${resolveLocalizationString('title', '$locale')},
        basicForm {
          ${resolveLocalizationString('buttonName', '$locale')},
          ${resolveLocalizationString('message', '$locale')},
          ${resolveLocalizationString('placeEmail', '$locale')},
          ${resolveLocalizationString('placeFullName', '$locale')},
          ${resolveLocalizationString('placePhone', '$locale')},
        },
      },
      _type == 'brandList' => {
        ...,
        ${resolveLocalizationString('title', '$locale')},
        routes[] -> {
          ...,
          content {
            ...,
            brandInfo {
              ...,
              ${resolveLocalizationString('name', '$locale')},
              ${resolveLocalizationString('title', '$locale')},
              ${resolveLocalizationString('desc', '$locale')},
              slug,
           },
          }
        },
        ${resolveLocalizationText('description', '$locale')},
        cta {
          ...,
          ${resolveLocalizationString('title', '$locale')},
          route->
        }
      },
    }
  }
[0]`;
export default module.exports = routeQuery.replace(/ +/g, '');
