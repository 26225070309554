import InteractiveImage from '@components/bits/InteractiveImage';
import SimpleBlockContent from '@components/bits/SimpleBlockContent';
import React, { FunctionComponent } from 'react';

import styles from './index.module.scss';
type Props = {
  title: string;
  subTitle: string;
  content;
  image?;
};

const ListElement: FunctionComponent<Props> = ({
  title,
  subTitle,
  content,
  image,
}) => {

  return (
    <div className={`${styles.root}`}>
      <span className={styles['image-wrapper']}>
        {image && <InteractiveImage image={image} interactive={false} width={600}/>}
      </span>
      <span className={styles['info-wrapper']}>
        <h4>{title}</h4>
        <p>{subTitle}</p>
        <SimpleBlockContent data={content} />
      </span>
    </div>
  );
};

export default ListElement;
