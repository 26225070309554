import useWindowDimensions from '@utils/hooks/useWindowDimension';
import iub from '@utils/ImageUrlBuilder';
import React, { FunctionComponent } from 'react';

import styles from './index.module.scss';

type Props = {
  image;
  localization: string;
};

interface Size {
  width: number | undefined;
  height: number | undefined;
}
const SingleImgMini: FunctionComponent<Props> = ({ image, localization }) => {
  const size: Size = useWindowDimensions();

  return (
    <div className={`${styles.root} ${styles[localization]}`}>
      {size.width > 768 ? (
        <div
          style={{ backgroundImage: `url(${iub(image?.asset).url()})` }}
          title={image?.alt}
          className={styles.singleDivImg}
        />
      ) : (
        <img className={styles.singleImg} src={iub(image?.asset).url()} alt={image?.alt} />
      )}
    </div>
  );
};

export default SingleImgMini;
