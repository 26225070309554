import CTALink from '@bits/CtaLink';
import SimpleBlockContent from '@bits/SimpleBlockContent';
import { basicInVewOptions } from '@utils/animations/fadeSettings';
import iub from '@utils/ImageUrlBuilder';
import Link from 'next/link';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import styles from './index.module.scss';
type Props = { title; routes; description; cta; alignTextCenter: boolean };

const BrandList: FunctionComponent<Props> = ({
  title,
  routes,
  description,
  cta,
  alignTextCenter,
}) => {
  const [hoveredBrand, sethoveredBrand] = useState(null);
  const { ref, inView } = useInView(basicInVewOptions);
  const imageWidth = 2000;


  useEffect(() => {
    let index = 0;
    const img = new Image();
    img.onload = () => {
      index = index + 1;
      if (index < routes.length) {
        img.src = iub(routes[index].content.brandInfo.figure.asset).width(imageWidth).url()
      }
    }

    img.src = img.src = iub(routes[index].content.brandInfo.figure.asset).width(imageWidth).url()

  }, [])

  return (
    <div
      ref={ref}
      className={`${styles.root} ${inView ? styles['in-view'] : ''} ${alignTextCenter ? styles.center : ''
        }`}
      style={
        routes[hoveredBrand]?.content?.brandInfo?.figure
          ? {
            backgroundImage: `url(${iub(
              routes[hoveredBrand].content.brandInfo.figure.asset
            ).width(imageWidth).url()})`,
          }
          : { backgroundColor: '#758C93' }
      }
      title={hoveredBrand && routes[hoveredBrand]?.content?.brandInfo?.figure?.alt}>
      <div className={styles['wrapper-outer']}>
        <div className={`wrapper-inner ${styles['wrapper-inner']}`}>
          <h4>{title}</h4>
          {routes?.map((val, key) => {
            const { content } = val;

            if (!val.brand || (val.brand && val.active === true)) {
              if (content?.brandInfo) {
                const data = content.brandInfo;
                return (
                  <Link key={key} as={`/${data.slug.current}`} href={`/${data.slug.current}`}>
                    <div
                      style={{ transitionDelay: `${key * 75}ms` }}
                      className={`${styles.link}`}
                      onMouseOver={() => sethoveredBrand(key)}
                      onFocus={() => sethoveredBrand(key)}
                      onMouseOut={() => sethoveredBrand(null)}
                      onBlur={() => sethoveredBrand(null)}>
                      <a>{data.name} </a> <span>00{key + 1}</span>
                    </div>
                  </Link>
                );
              }
            }
            return null;
          })}
          <div className={styles.ctaWrapper} style={{ transitionDelay: `${75 * routes.length}ms` }}>
            <SimpleBlockContent data={description} />
            {cta && (
              <CTALink title={cta.title} cta={cta} backgroundColor={'white'} color={'black'} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandList;
