import React from 'react';

const ThinArrowRight = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" fill="none" viewBox="0 0 15 10">
      <path fill="#000" d="M0 4.454H12.868V5.444H0z"></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M13.553 5.6l.7-.7-.7-.7-4.2-4.2-.7.7 4.2 4.2-4.2 4.199.7.7 4.2-4.2z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default React.memo(ThinArrowRight);
