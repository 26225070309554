import iub from '@utils/ImageUrlBuilder';
import React, { FunctionComponent } from 'react';

import styles from './index.module.scss';
type Props = { logo; backgroundColor: string };

const BrandLogo: FunctionComponent<Props> = ({ logo, backgroundColor }): JSX.Element => {
  const style = {
    backgroundColor: backgroundColor,
  };

  return (
    <div className={styles.root}>
      <div style={style} className={styles.wrapper}>
        <div
          style={{ backgroundImage: `url(${iub(logo?.asset).url()})` }}
          title={logo?.alt}
          className={styles.img}
        />
      </div>
    </div>
  );
};

export default BrandLogo;
