import React, { memo } from 'react';

const GrayArrowDown = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 26 26">
      <g
        stroke="#435053"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        opacity="0.5">
        <path d="M6.5 16.25l6.5 6.5 6.5-6.5M13 3.25v18.417M13 22.75v-1.083"></path>
      </g>
    </svg>
  );
};

export default memo(GrayArrowDown);
