import SimpleBlockContent from '@bits/SimpleBlockContent';
import React, { FunctionComponent } from 'react';

import ImageGallery from '../ImageGallery';
import styles from './index.module.scss';

type Props = {
  text;
  imageGallery;
};

const TextWithImageGallery: FunctionComponent<Props> = ({ text, imageGallery }): JSX.Element => {
  return (
    <div className={`${styles.root}`}>
      <div className={styles['text']}>
        <SimpleBlockContent data={text} />
      </div>
      <ImageGallery {...imageGallery} />
    </div>
  );
};

export default TextWithImageGallery;
