import SimpleBlockContent from '@bits/SimpleBlockContent';
import GrayArrowDown from '@components/bits/icons/GrayArrowDown';
import React, { FunctionComponent } from 'react';
import { useState } from 'react';

import styles from './index.module.scss';

type Props = { title: string; subTitle: string; content };

const AccordionElement: FunctionComponent<Props> = ({ title, subTitle, content }): JSX.Element => {
  const [active, setActive] = useState(false);

  return (
    <div className={styles.root}>
      <button onClick={() => setActive(!active)}>
        <span>
          <h4>{title}</h4>
          <p>{subTitle}</p>
        </span>
        <GrayArrowDown />
      </button>
      {active && (
        <div>
          <SimpleBlockContent data={content} />
        </div>
      )}
    </div>
  );
};

export default AccordionElement;
