import React, { FunctionComponent } from 'react';

import AccordionElement from '../AccordionElement';
import styles from './index.module.scss';
type Props = { elements };

const AccordionList: FunctionComponent<Props> = ({ elements }): JSX.Element => {

  return (
    <div className={styles.root}>
      {elements &&
        elements.map((val, key) => {
          return <AccordionElement key={key} {...val} />;
        })}
    </div>
  );
};

export default AccordionList;
