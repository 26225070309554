import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useRef, useState } from 'react';

import styles from './index.module.scss';


type Props = {
  cta?: {
    title: string;
    link: string;
    brand: {
      content: {
        brandInfo: {
          slug: {
            current: string;
          };
        };
      };
    };
    slug: { current: string };
    route?: {
      slug: { current: string };
      content?: {
        brandInfo?: {
          slug?;
        };
      };
    };
  };
  title: string;
  backgroundColor?: string;
  color?: string;
  url?: string;
  download?: boolean | string;
};

const CTALink: FunctionComponent<Props> = ({
  cta,
  title = 'no slug',
  backgroundColor = 'black',
  color = 'white',
  url,
  download = false,
}) => {
  const [hovered, setHovered] = useState(false);
  const { asPath } = useRouter()
  
  const style = useRef({
    backgroundColor: backgroundColor,
    color: color,
    border: '1px solid ' + backgroundColor,
  });

  const hoverStyle = useRef({
    backgroundColor: 'transparent',
    color: backgroundColor,
    border: '1px solid ' + backgroundColor,
  });

  if (url?.substring(0, 2) === '/#') {
    const newURL = `${asPath}${url}`;
    return (
      <Link href={newURL}>
        <span
          style={!hovered ? style.current : hoverStyle.current}
          className={styles.root}
          onMouseOver={() => setHovered(true)}
          onFocus={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
          onBlur={() => setHovered(false)}>
            {title || 'no url'}
          </span>
        </Link>
    );
  }

  if (url) {
    return (
      <Link href={`${url}?dl=${download}`}>
        <span
          style={!hovered ? style.current : hoverStyle.current}
          className={styles.root}
          onMouseOver={() => setHovered(true)}
          onFocus={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
          onBlur={() => setHovered(false)}>
            {title || 'no url'}
          </span>
        </Link>
    );
  }
  
  if (!cta.route) {
    const url =
      cta.link || cta?.brand?.content?.brandInfo?.slug?.current || '/' + cta?.slug?.current;

    if (url) {
      return (
        <span
          style={!hovered ? style.current : hoverStyle.current}
          className={styles.root}
          onMouseOver={() => setHovered(true)}
          onFocus={() => setHovered(true)}
          onMouseOut={() => setHovered(false)}
          onBlur={() => setHovered(false)}>
          <Link href={url}>{title || 'no slug'}</Link>
        </span>
      );
    }

    return <span className={styles.root}>Needs an url</span>;
  }

  const link = cta?.route?.slug?.current || cta?.route?.content?.brandInfo?.slug?.current;

  return (
    <Link href={'/' + link}>
      <span
        style={!hovered ? style.current : hoverStyle.current}
        className={styles.root}
        onMouseOver={() => setHovered(true)}
        onFocus={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        onBlur={() => setHovered(false)}>
        {title || cta?.title || 'no slug'}
      </span>
    </Link>
  );
};

export default CTALink;
